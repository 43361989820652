// work data

export const Work = [
    {
        id:1,
        name:"Paystrapp",
        description:"It is built on top of the ReactNative, Nodejs, Expressjs Mongodb and it is a finance management software.",
        tags:["react","gsap","nodejs","Mongodb", "Expressjs", "react-native"],
        
        demo:"https://play.google.com/store/apps/details?id=com.paystrapp",
        github:"https://github.com/pryhmez/paystrappmobile"
    },
    {
        id:2,
        name:"Inaaga",
        description:"A Ride hailing app built with Java and Android studio",
        tags:["react","android"],
        
        demo:"https://play.google.com/store/apps/details?id=in.ighub.inaagaUser",
        github:"https://github.com/pryhmez/inaagamobile"
        
    },
    {
        id:3,
        name:"Sebang Connect",
        description:"An app for battery warranty checking in south korea",
        tags:["react-native","api","nodejs"],
        
        demo:"https://play.google.com/store/apps/details?id=com.sebang.rewards",
        github:"https://github.com/codebucks27/devto-clone"
    },
    {
        id:4,
        name:"Sidebar Menu",
        description:"A cool looking sidebar menu build using ReactJS and styledComponents.It has really smooth animations.",
        tags:["react","styledComponents","react-router"],
        
        demo:"https://react-sidebar.vercel.app/",
        github:"https://github.com/codebucks27/react-sidebar"
       
    },{
        id:5,
        name:"Todo App(React + Redux)",
        description:"A todo app build using Redux-toolkit and framer-motion for smooth animations.",
        tags:["react","redux","framer-motion"],
        
        demo:"http://react-redux-todo-app-git-main-codebucks27.vercel.app/",
        github:"https://github.com/codebucks27/React-Redux-Todo-App"
        
    },
    {
        id:6,
        name:"Responsive Navigation Menu",
        description:"A reponsive navbar menu component in ReactJS with react-router functionalities. Built from scratch.",
        tags:["react","react-router","css"],
        
        demo:"http://react-responsive-navbar-codebucks27.vercel.app/",
        github:"https://github.com/codebucks27/React-responsive-navbar"
        
    },
    
  
] 