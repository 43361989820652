import React, {useEffect, useRef} from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "./Themes";
import { Design, Develope, MobileDev } from "./AllSvgs";
import { motion } from "framer-motion";

import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitlte";

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: auto;
  height: 400vh;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow-x: scroll;
`;

const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  width: 30rem;
  min-width: 25rem;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;
  cursor: pointer;
  margin-right: 100px;

  font-family: "Ubuntu Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }

  &:last-child {
    margin-right: 0px;
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);

  ${Main}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
    }
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`;

const Description = styled.div`
  color: ${(props) => props.theme.text};
  font-size: calc(0.6em + 1vw);
  padding: 0.5rem 0;

  ${Main}:hover & {
    color: ${(props) => props.theme.body};
  }

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul,
  p {
    margin-left: 2rem;
  }
`;

const Scrollable = styled(motion.div)`
  background-color: ${(props) => props.theme.body};
  display: flex;
  // flex-direction: row;
  // width: 80%;
  // overflow-x: scroll;

  position: fixed;
  top: 12rem;
  left: calc(10rem + 15vw);
  &::-webkit-scrollbar {
    width: 0 !important;
  }
`;

// Framer-motion Configuration
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
};

const MySkillsPage = () => {

  const ref = useRef(null);


  useEffect(() => {
    let element = ref.current;

    const rotate = () => {
      element.style.transform = `translateX(${-window.pageYOffset}px)`;

      console.log(-window.pageYOffset)

    };

    window.addEventListener("scroll", rotate);
    return () => {
      window.removeEventListener("scroll", rotate);
    };
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <Box>
        <LogoComponent theme="light" />
        <SocialIcons theme="light" />
        <PowerButton />
        <ParticleComponent theme="light" />

        <Scrollable ref={ref} variants={container} initial="hidden" animate="show">
          <Main>
            <Title>
              <Design width={40} height={40} /> Designer
            </Title>
            <Description>
              I love to create design which speaks, Keep it clean, minimal and
              simple.
            </Description>
            <Description>
              <strong>I like to Design</strong>
              <ul>
                <li>Web Design</li>
                <li>Mobile Apps</li>
              </ul>
            </Description>
            <Description>
              <strong>Tools</strong>
              <ul>
                <li>Figma</li>
              </ul>
            </Description>
          </Main>

          <Main>
            <Title>
              <Develope width={40} height={40} /> Frontend Developer
            </Title>
            <Description>
              I value business or brand for which i'm creating, thus i enjoy
              bringing new ideas to life.
            </Description>
            <Description>
              <strong>Skills</strong>
              <p>
                Html, Css, Js, React, Redux, Sass, Bootstrap, Tailwind, Firebase
                etc.
              </p>
            </Description>
            <Description>
              <strong>Tools</strong>
              <p>VScode, Github, Codepen etc.</p>
            </Description>
          </Main>

          <Main>
            <Title>
              <Develope width={40} height={40} /> Backend Developer
            </Title>
            <Description>
              Let me create smooth, efficient and optimized backend applications
              to serve your business.
            </Description>
            <Description>
              <strong>Skills</strong>
              <p>
                Nodejs, ExpressJs, Js, Sql, MongoDb, Python, Django, GraphQl,
                Firebase etc.
              </p>
            </Description>
            <Description>
              <strong>Tools</strong>
              <p>VScode, Github, Codepen etc.</p>
            </Description>
          </Main>

          <Main>
            <Title>
              <MobileDev width={40} height={40} /> Mobile App Developer
            </Title>
            <Description>
              I make really good looking mobile apps with sleek UIs, with
              efficient and optimized code. I also launch to appstores.
            </Description>
            <Description>
              <strong>Skills</strong>
              <p>
                IOS, Android, Java, React-Native, Expo, Flutter, Kotlin etc.
              </p>
            </Description>
            <Description>
              <strong>Tools</strong>
              <p>
                VScode, Github, Android-Studio, Playstore, AppleStore, Xcode
                etc.
              </p>
            </Description>
          </Main>
        </Scrollable>

        <BigTitle text="SKILLS" top="80%" right="30%" />
      </Box>
    </ThemeProvider>
  );
};

export default MySkillsPage;
